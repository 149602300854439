<template>
    <div class="p-grid crud-demo">
        <div class="p-col-12">
            <div class="card">
                <Toast/>
                <Toolbar class="p-mb-4">
                    <template v-slot:left>
                        <Button label="Ajouter" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                        <Button label="Supprimer" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedItems || !selectedItems.length" />
                    </template>

                    <template v-slot:right>
                        <!--FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block" /-->
                        <Button label="Exporter" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                    </template>
                </Toolbar>

                <DataTable ref="dt" :value="items" v-model:selection="selectedItems" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                            <h5 class="p-m-0">Sites</h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="id" header="#ID">
                        <template #body="slotProps">
                            <span class="p-column-title">#Id</span>
                            {{slotProps.data.id}}
                        </template>
                    </Column>
                    <Column field="host" header="Host" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">Host</span>
                            {{slotProps.data.host}}
                        </template>
                    </Column>
                    <Column field="email" header="Email" >
                        <template #body="slotProps">
                            <span class="p-column-title">Email</span>
                            {{slotProps.data.email}}
                        </template>
                    </Column>
                    <Column field="contactFromEmail" header="Contact" >
                        <template #body="slotProps">
                            <span class="p-column-title">Contact</span>
                            {{slotProps.data.contactFromName}} &lt;{{slotProps.data.contactFromEmail}}&gt; 
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editItem(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteItem(slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible="itemDialog" :style="{width: '450px'}" header="Product Details" :modal="true" class="p-fluid">
                    <form name="item" id="item-form" @submit.prevent="saveItem(!v$.$invalid)" novalidate>
                        <div class="p-field">
                            <label for="host">Host</label>
                            <InputText id="host" v-model.trim="item.host" required="true" autofocus :class="{'p-invalid':v$.item.host.$invalid && isSubmitted}" />
                            <div v-if="(v$.item.host.$invalid && isSubmitted) || v$.item.host.$pending.$response" class="p-error">
                                <small v-if="v$.item.host.required.$invalid">Veuillez renseigner ce champ.</small>
                            </div>
                        </div>
                        <div class="p-field">
                            <label for="sender-email">Sender Email</label>
                            <InputText id="sender-email" v-model.trim="item.email" required="true" autofocus :class="{'p-invalid':v$.item.email.$invalid && isSubmitted}" />
                            <div v-if="(v$.item.email.$invalid && isSubmitted) || v$.item.email.$pending.$response" class="p-error">
                                <small v-if="v$.item.email.required.$invalid">Veuillez renseigner ce champ.</small>
                                <small v-if="v$.item.email.email.$invalid">Adresse Email incorrecte.</small>
                            </div>
                        </div>
                        <div class="p-formgrid p-grid">
                            <div class="p-field p-col">
                                <label for="contact-name">Contact Name</label>
                                <InputText id="contact-name" v-model.trim="item.contactFromName" required="true" :class="{'p-invalid':v$.item.contactFromName.$invalid && isSubmitted}" />
                                <div v-if="(v$.item.contactFromName.$invalid && isSubmitted) || v$.item.contactFromName.$pending.$response" class="p-error">
                                    <small v-if="v$.item.contactFromName.required.$invalid">Veuillez renseigner ce champ.</small>
                                </div>
                            </div>
                            <div class="p-field p-col">
                                <label for="contact-email">Contact Email</label>
                                <InputText id="contact-email" type="email" v-model.trim="item.contactFromEmail" required="true" :class="{'p-invalid':v$.item.contactFromEmail.$invalid && isSubmitted}" />
                                <div v-if="(v$.item.contactFromEmail.$invalid && isSubmitted) || v$.item.contactFromEmail.$pending.$response" class="p-error">
                                    <small v-if="v$.item.contactFromEmail.required.$invalid">Veuillez renseigner ce champ.</small>
                                    <small v-if="v$.item.contactFromEmail.email.$invalid">Adresse Email incorrecte.</small>
                                </div>
                            </div>
                        </div>
                    </form>

                    <template #footer>
                        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="Save" icon="pi pi-check" class="p-button-text" type="submit" form="item-form" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteItemDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                        <span v-if="item">Are you sure you want to delete <b>{{item.host}}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemDialog = false"/>
                        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteItem" />
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteItemsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
                        <span v-if="item">Are you sure you want to delete the selected products?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteItemsDialog = false"/>
                        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedItems" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

export default {
    data() {
        return {
            items: null,
            itemDialog: false,
            deleteItemDialog: false,
            deleteItemsDialog: false,
            item: {},
            selectedItems: null,
            filters: {},
            isSubmitted: false
        }
    },
    setup: () => ({ v$: useVuelidate() }),
    mounted() {
        this.reloadData();
    },
    validations() {
        return {
            item: {
                host: {required},
                email: {required, email},
                contactFromEmail: {required, email},
                contactFromName: {required} 
            } 
        } 
    },
    methods: {
        reloadData() {
            this.$resources.site.list().then(response => this.items = response.data);   
        },
        openNew() {
            this.item = {};
            this.isSubmitted = false;
            this.itemDialog = true;
        },
        hideDialog() {
            this.itemDialog = false;
            this.isSubmitted = false;
        },
  
        saveItem(isValid) {
            this.isSubmitted = true;
            if (!isValid) return;

            if (this.item.id) {
                this.$resources.site.update(this.item.id, this.item).then( (data) => {
                    this.reloadData();
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Site mise à jour', life: 3000});
                    this.items[this.findIndexById(this.item.id)] = data;
                    this.itemDialog = false;
                    this.item = {};
                });
            } else {
                this.$resources.site.create(this.item).then( (data) => {
                    this.items.push(data);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Site ajouté', life: 3000});

                    this.itemDialog = false;
                    this.item = {};
                });
            } 
        },
        editItem(item) {
            this.item = {...item};
            this.itemDialog = true;
        },
        confirmDeleteItem(item) {
            this.item = item;
            this.deleteItemDialog = true;
        },
        deleteItem() {
            this.$resources.site.delete(this.item.id).then(() => {
                this.items = this.items.filter(val => val.id !== this.item.id);
                this.deleteItemDialog = false;

                this.tool = {};
                this.$toast.add({severity:'success', summary: 'Successful', detail: 'Site supprimé', life: 3000});
            });

        },
        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        confirmDeleteSelected() {
            this.deleteItemsDialog = true;
        },
        deleteSelectedItems() {
            this.items = this.items.filter(val => !this.selectedItems.includes(val));
            this.deleteItemsDialog = false;
            this.selectedItems = null;
            this.$toast.add({severity:'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
        }
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;
        .p-button {
            margin-bottom: .25rem;
        }
    }
</style>
